import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueCompositionAPI from '@vue/composition-api'

import '@/sass/stylesheet.sass'

import './bootstrap'

Vue.use(VueCompositionAPI)

/**
 * Intercept expired/missing token responses and retry if necessary.
 *
 * @param {object} error - Axios error object.
 * @returns {Promise} Handler.
 */
const interceptorFn = (error) => {
  const status = error.response ? error.response.status : null
  const inExcludedUrl = ['/.netlify/functions/login', '/.netlify/functions/logout'].includes(error.config.url)

  if (status === 401 && !inExcludedUrl) { store.dispatch('customer/logout') }

  return Promise.reject(error)
}
axios.interceptors.response.use((response) => response, interceptorFn)

Vue.config.productionTip = false

Vue.mixin({ methods: { urlFor: (value) => window.urlFor(value) } })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
