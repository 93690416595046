<template>
    <section class="hero is-medium is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-half">
              <h1 class="has-text-white is-size-2 pr-6 mr-6 has-text-weight-bold">For your OpenSolar® software integration & automation needs, contact us:</h1>
            </div>
            <div class="column is-one-third">
              <form
                data-netlify="true"
                data-netlify-honeypot="bot"
                method="POST"
                name="contact"
                @submit.prevent="onSubmit"
                v-if="!isSubmitted"
            >
              <input class="is-hidden" v-model="form.bot" />
              <div class="field">
                <label class="label">Name</label>
                <div class="control">
                  <input v-model="form.name" class="input has-background-primary" type="text" placeholder="Your Name" required>
                </div>
              </div>
              <div class="field">
                <label class="label">Company Name</label>
                <div class="control">
                  <input v-model="form.companyname" class="input has-background-primary" type="text" placeholder="Company Name">
                </div>
              </div>
              <div class="field">
                <label class="label">Email</label>
                <div class="control">
                  <input v-model="form.email" class="input has-background-primary" type="email" placeholder="Email Address" required>
                </div>
              </div>
              <div class="field">
                <label class="label">Describe your automation needs</label>
                <div class="control">
                  <textarea v-model="form.message" class="textarea has-background-primary" placeholder="Describe your automation needs" required></textarea>
                </div>
              </div>
              <div class="field is-grouped">
                <div class="control">
                  <button class="button is-outlined">Send</button>
                </div>
              </div>
              </form>
              <div v-if="isSubmitted" class="notification is-dark">
                Thank you for contacting us, we've received your message and will be in touch shortly.
              </div>
              <div v-if="isError" class="notification is-danger mt-6">
                There was a problem submiting the form, it wasn't your fault. Alternatively you may email solar@murphyvarley.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import qs from 'qs'
import useVuelidate from '@vuelidate/core'

const form = {
  'form-name': 'contact',
  bot: '',
  name: '',
  companyname: '',
  email: '',
  message: ''
}

export default {
  name: 'ContactSection',
  props: {
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      form: { ...form },
      isSubmitted: false,
      isError: false
    }
  },
  methods: {
    onSubmit () {
      // eslint-disable-next-line no-undef
      axios.post(window.location, qs.stringify(this.form))
        .then(() => {
          this.isSubmitted = true
          this.isError = false
          this.form = { ...form }
        })
        .catch(() => {
          this.isError = true
        })
    }

  }
}
</script>

<style scoped lang="scss">
</style>
