/* eslint-disable no-undef */
import { defaultMutations } from 'vuex-easy-access'

const state = { pages: { '*': {} }, preview: { '*': {} } }

const mutations = { ...defaultMutations(state) }

const actions = {}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
