<template>
  <div>
    <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <img :src="require('@/assets/mv-logo-initials.svg')" />
        </a>
        </div>
         <div class="navbar-end">
          <div class="navbar-item has-text-white has-text-weight-bold is-hidden-touch">
            Solar Connect
          </div>
        </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  data () {
    return {
      menuOpen: false
    }
  },
  watch: {
    $route (to, from) {
      this.menuOpen = false
    }
  },
  methods: {
    toggleMenu () {
      this.menuOpen = !this.menuOpen
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.overlay {
  position: fixed;
  width: 100%;
  z-index: 30;

  &__hidden {
    display: none;
  }
}

.menu-list {
  a {
    &:hover {
      background: none;
      color: rgb(240,203,0) !important;
    }
  }
}

.navbar-burger__close {
  span:first-child {
    transform: rotate(45deg) translateX(3.5px);
  }
  span:last-child {
    transform: rotate(-45deg) translateX(3.5px);
  }
}
</style>
