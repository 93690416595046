<template>
  <div class="home">
    <section class="hero is-medium is-black background-cover" :style="{ 'background-image': `url('${require('@/assets/solar-connect-bg1.jpg')}')`, 'background-position-y': `${scrollPercentage}%` }">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-half">
              <h1 class="hero-title pb-0">Tailored<br/> OpenSolar®<br /> Automations</h1>
              <h3 class="has-text-primary is-size-3 pb-4 has-text-weight-bold">with Solar Connect</h3>
              <p>
                Are you looking to integrate your current CRM, sales and accounting software with OpenSolar®, the world's leading solar design and proposal software, to streamline and strengthen your processes? Then we can help.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-medium is-dark background-cover" :style="{ 'background-image': `url('${require('@/assets/solar-connect-bg2.jpg')}')`, 'background-position-y': `${scrollPercentage}%` }">
      <div class="hero-body">
          <div class="container has-text-white">
              <div class="content is-dark has-text-white">
                  <div class="columns">
                      <div class="column is-half-desktop">
                          <h4 class="has-text-white is-size-4-touch is-size-2-desktop">Reduce the cost of operating your solar business, and scale your profit with <span class="has-text-primary">Solar Connect</span> automations</h4>
                      </div>
                      <div class="column is-half">
                        <h3 class="has-text-white is-size-6 pt-2">Automate your operations, by integrating your OpenSolar® account with your other applications, for example:</h3>
                          <ul>
                            <li>Automate invoicing with dynamic line items</li>
                            <li>Sync OpenSolar® contacts and pricing with your CRM</li>
                            <li>Funnel leads into your OpenSolar® Projects</li>
                            <li>Get notified when a prospect views proposal</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
    <section class="hero is-medium is-dark">
      <div class="hero-body">
        <div class="container">
          <h3 class="has-text-white is-size-5-touch is-size-3-desktop">We provide software automations for solar installer business of all sizes, from simple notification systems to more complex <span class="has-text-primary">bespoke applications,</span> starting from £3,500 or <span class="has-text-primary">monthly plans</span> scaled to your usage. Contact us for your personalised quote.</h3>
        </div>
      </div>
    </section>
    <ContactSection />
  </div>
</template>

<script>

import ContactSection from '@/components/ContactSection'

export default {
  name: 'HomeView',
  components: {
    ContactSection
  },
  data () {
    return {
      scrollPercentage: 0
    }
  },
  mounted () {
    document.addEventListener('scroll', this.scrollPercent)
  },
  methods: {
    scrollPercent (event) {
      this.scrollPercentage = document.documentElement.scrollTop / document.documentElement.scrollHeight * 100
    }
  }
}
</script>

<style lang="scss" scoped>
.video-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 10%;
  min-height: 100%;
  z-index: -1;
  object-fit: cover;
  object-position: center;
}
</style>
